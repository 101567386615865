.App {
  text-align: center;
  /* background:lightcyan; */
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

Form {
  background:lightblue;
  display:flex;
  justify-content: space-between;
  flex-direction: column;
  width:50%;
  margin:auto;
  border-radius:23px;

}
Input {
  padding:20px;
  margin:auto;
  margin-top:20px;
  
}

.title {
  /* background:teal; */
  border-radius:10px;
  width:50%;
  margin:100px auto 20px;
  padding:10px;
}
button {
  width:20%;
  margin:auto;
  margin-top:10px;
  margin-bottom:10px;
  padding:5px;
  border-radius:2px;
}
.Star {
  width:80%;
  margin: 30px auto;
}
.form{
  margin:20px auto;
}

textarea {
  height:100px;
  margin:20px auto;
  width:80%;
}